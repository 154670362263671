import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  List,
  ListItem,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  makeStyles,
  Button,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import Scrollbar from '../scrollbar';
import PaperDate from '../../components/paper-date';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
  accordionHover: {
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
    },
  },
}));

const AccordionDataListNew = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Scrollbar maxHeight='500px' width='100%'>
        {props.day === '24' && (
          <Box px={2} mb={{ xs: 3, sm: 3, md: 1 }} mt={1} display={isMobile ? 'block' : 'flex'} flexWrap='nowrap'>
            <Box mr={{ md: 2 }} mb={2} onClick={() => props.handleTwentyFourIndex('greenChain')}>
              <Button
                fullWidth
                variant={props.twentyFourIndex === 'greenChain' ? 'contained' : 'outlined'}
                color='primary'>
                Green Chain Conference
              </Button>
            </Box>
            <Box mr={{ md: 2 }} onClick={() => props.handleTwentyFourIndex('globalProsperityConference')}>
              <Button
                fullWidth
                variant={props.twentyFourIndex === 'globalProsperityConference' ? 'contained' : 'outlined'}
                color='primary'>
                {/* Mohammed Bin Rashid Initiative for Global Prosperity
                 */}
                Global Prosperity Conference
              </Button>
            </Box>
            <Box mr={{ md: 2 }} onClick={() => props.handleTwentyFourIndex('ausBusinessDay')}>
              <Button
                fullWidth
                variant={props.twentyFourIndex === 'ausBusinessDay' ? 'contained' : 'outlined'}
                color='primary'>
                AUS Business Day
              </Button>
            </Box>
            <Box mr={{ md: 2 }} onClick={() => props.handleTwentyFourIndex('skolkovoPoweredSession')}>
              <Button
                fullWidth
                variant={props.twentyFourIndex === 'skolkovoPoweredSession' ? 'contained' : 'outlined'}
                color='primary'>
                Skolkovo Powered Session
              </Button>
            </Box>
          </Box>
        )}
        {props.day === '25' && (
          <Box px={2} mb={{ xs: 3, sm: 3, md: 1 }} mt={1} display={isMobile ? 'block' : 'flex'} flexWrap='nowrap'>
            <Box mr={{ md: 2 }} mb={2} onClick={() => props.handleTwentyFiveIndex('confindustriaBusinessDay')}>
              <Button
                fullWidth
                variant={props.twentyFiveIndex === 'confindustriaBusinessDay' ? 'contained' : 'outlined'}
                color='primary'>
                Confindustria Business Day
              </Button>
            </Box>
            <Box onClick={() => props.handleTwentyFiveIndex('UKUAEBusinessDay')}>
              <Button
                fullWidth
                variant={props.twentyFiveIndex === 'UKUAEBusinessDay' ? 'contained' : 'outlined'}
                color='primary'>
                UK-UAE Business Day
              </Button>
            </Box>
          </Box>
        )}
        {props.day === '26' && (
          <Box px={2} mb={{ xs: 3, sm: 3, md: 1 }} mt={1} display={isMobile ? 'block' : 'flex'} flexWrap='nowrap'>
            <Box mr={{ md: 2 }} mb={2} onClick={() => props.handleTwentySixIndex('generationFuture')}>
              <Button
                fullWidth
                variant={props.twentySixIndex === 'generationFuture' ? 'contained' : 'outlined'}
                color='primary'>
                Generation Future
              </Button>
            </Box>
            <Box onClick={() => props.handleTwentySixIndex('esports')}>
              <Button fullWidth variant={props.twentySixIndex === 'esports' ? 'contained' : 'outlined'} color='primary'>
                esports
              </Button>
            </Box>
          </Box>
        )}
        {props.subtitle && (
          <Box px={2}>
            <Typography variant='h5' color='primary' dangerouslySetInnerHTML={{ __html: props.subtitle }} />
          </Box>
        )}
        {props.data.map((_data, index) => (
          <Box mt={2} className={classes.accordionHover} key={index}>
            <Accordion
              key={index}
              elevation={0}
              disabled={_data.details.length === 0}
              style={{ backgroundColor: 'transparent', opacity: '1' }}>
              {_data.details.length > 0 ? (
                <AccordionSummary expandIcon={<ChevronRightIcon />}>
                  <Grid container>
                    <Grid item xs={isMobile ? 3 : 1}>
                      {_data.time ? (
                        <PaperDate
                          time={_data.time}
                          am={_data.am}
                          day={_data.day || props.day}
                          month={_data.month || props.month}
                          year={_data.year || props.year}
                          agenda={props.isAgenda}
                          isGreen={_data.isGreen}
                        />
                      ) : (
                        <PaperDate
                          time={_data.time}
                          am={_data.am}
                          day={_data.day || props.day}
                          month={_data.month || props.month}
                          year={_data.year || props.year}
                          agenda={props.isAgenda}
                          isGreen={_data.isGreen}
                          isTimeSeparate={props.isTimeSeparate}
                          timeFrom={_data.timeFrom}
                          timeTo={_data.timeTo}
                        />
                      )}
                    </Grid>
                    <Grid item xs={isMobile ? 9 : 11}>
                      {_data.venue && (
                        <Box ml={1}>
                          <Typography className='text-uppercase text-bold'>VENUE: {_data.venue}</Typography>
                        </Box>
                      )}
                      {_data.venueTitle && (
                        <Box ml={1}>
                          <Typography className='text-uppercase text-bold'>{_data.venueTitle}</Typography>
                        </Box>
                      )}
                      {/* <Box ml={1}>
                        <Typography className='text-uppercase text-bold'>{_data.time}</Typography>
                      </Box> */}
                      <Box ml={1}>
                        <Typography className='text-bold'>{_data.summary}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionSummary>
              ) : (
                <AccordionSummary style={{ opacity: '1' }}>
                  <Grid container>
                    <Grid item xs={isMobile ? 3 : 1}>
                      {_data.time ? (
                        <PaperDate
                          time={_data.time}
                          am={_data.am}
                          day={_data.day || props.day}
                          month={_data.month || props.month}
                          year={_data.year || props.year}
                          agenda={props.isAgenda}
                          isGreen={_data.isGreen}
                        />
                      ) : (
                        <PaperDate
                          time={_data.time}
                          am={_data.am}
                          day={_data.day || props.day}
                          month={_data.month || props.month}
                          year={_data.year || props.year}
                          agenda={props.isAgenda}
                          isGreen={_data.isGreen}
                          isTimeSeparate={props.isTimeSeparate}
                          timeFrom={_data.timeFrom}
                          timeTo={_data.timeTo}
                        />
                      )}
                    </Grid>
                    <Grid item xs={isMobile ? 9 : 11}>
                      {props.showTime && (
                        <Box ml={1}>
                          <Typography className='text-uppercase text-bold'>{_data.time}</Typography>
                        </Box>
                      )}
                      {_data.venue && (
                        <Box ml={1}>
                          <Typography className='text-uppercase text-bold'>VENUE: {_data.venue}</Typography>
                        </Box>
                      )}
                      {_data.venueTitle && (
                        <Box ml={1}>
                          <Typography className='text-uppercase text-bold'>{_data.venueTitle}</Typography>
                        </Box>
                      )}
                      <Box ml={1}>
                        <Typography className='text-bold'>{_data.summary}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionSummary>
              )}
              {/*TODO: disable the accordion from expanding when there are no AccordionDetails */}
              {_data.details.length > 0 ? (
                <AccordionDetails>
                  <List>
                    {_data.details.map((detail, index) => (
                      <ListItem key={index}>
                        <Typography color='textSecondary' dangerouslySetInnerHTML={{ __html: detail }}></Typography>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              ) : null}
            </Accordion>
          </Box>
        ))}
      </Scrollbar>
    </>
  );
};

export default AccordionDataListNew;
