import React from 'react';
import ImageContainer from '../../components/image-container';
import { Typography, Box, Grid, Button } from '@material-ui/core';
import { Link } from 'gatsby';
import Clamp from 'react-multiline-clamp';
import VideoContainer from '../../components/video-contanier/video-container';
import Fade from 'react-reveal/Fade';
// import AOS from 'aos';
// import '../../styles/sass/aos.css';

const StaticImageTitleDescription = (props) => {
  // useEffect(() => {
  //   AOS.init();
  // }, []);
  return (
    <Fade bottom>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.video ? (
            <VideoContainer src={props.url} thumbNail={props.filename} controls={true} />
          ) : (
            <ImageContainer filename={props.filename} altText='#GMIS2021' />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5' color='textPrimary'>
            <Box fontWeight='fontWeightBold'>{props.title}</Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography paragraph color='textSecondary'>
            {props.lines ? <Clamp lines={props.lines}>{props.description}</Clamp> : props.description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Link
            to={props.ctaLink}
            className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary'>
            <Button color='primary'>{props.ctaText}</Button>
          </Link>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default StaticImageTitleDescription;
